:root {
  --mdc-theme-primary: #4794d4 !important;
}
body{
  padding: 0;
  margin: 0;
}
p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #3a4149;
}
.bigIcon {
  align-self: center; 
  font-size: 36px; 
  padding-top: 10px;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 3;
}
#canvasWrap {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.unselectable {
  touch-action: none;
  user-select: none;
  pointer-events: none;
}

#canvasUI {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;

  color: #3a4149;

  pointer-events:none;
}
#canvasUI > * {
  pointer-events: auto;
}

#helpInfoContainer{
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}
/* Temp Help Screen */
#helpScreen {
  display: block;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(231, 231, 231, 0.95);
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  z-index: 5;

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  transition: opacity 0.15s linear, visibility 0.15s linear;
  -o-transition: opacity 0.15s linear, visibility 0.15s linear;
  -moz-transition: opacity 0.15s linear, visibility 0.15s linear;
  -webkit-transition: opacity 0.15s linear, visibility 0.15s linear;
}
#helpScreen.show {
  visibility:visible;
  opacity: 1;
}
.column {
  float: left;
  width: 30%;
  max-width: 200px;
  height: 40vh;
  max-height: 200px;
  border-bottom: 1px rgba(235, 235, 235, 0.877);
  margin: 1.665%;
}
.row{
  display: flex;
  justify-content: center;
}
.row:after {
  content: "";
  display: table;
  max-height: 200px;
  clear: both;
}

#imr-logo{
  position: absolute;
  cursor: pointer;
  padding-left: 5px;
  z-index: 1;
  bottom: 0;
}

#zoomControls{
  position: absolute;
  cursor: pointer;
  padding: 1px;
  z-index: 1;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.zoom{
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5%;
  margin: 2px;
  background-color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size:20px;
  text-align: center;
}

#timelineButton,
#helpButton {
  /* display: inline-block; */
  position: relative;
  float: right;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  margin: 5px;
  border: 0px;
  /* background-color: #e9e9e9c7; */ /* Was this intentional? */
}

button {
  display: flex;
  position: relative;
  outline: none;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 20px;
  margin: 5px;
  border: #3a4149 solid 0px;
  color: #3a4149;
  background: #e7ebee;
  line-height: 1;
  font-size: 16px;
  z-index: 4;
  left: 0;

  transition: all 0.1s;
  -o-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -webkit-transition: all 0.1s;
	
  transition-property: color, background;
  -o-transition-property: color, background;
  -moz-transition-property: color, background;
  -webkit-transition-property: color, background;
}
button:hover {
  background: #8e9092;
  color: #f9fafc;
}
button span.label {
  padding-left: 10px;
}
button.mdc-tab {
  border-radius: 0;
}
button.fit {
  width: fit-content;
  min-width: fit-content;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: fit-content;

  color: #3a4149;
  font-family: Arial, Helvetica, sans-serif;
}

.space-card {
  display:  flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  /* padding: 10px; */
  margin: 5px;
  overflow: hidden;

  width: fit-content;
  color: white;
  font-weight: bold;
  font-size: larger;

  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.space-card.mdc-card {
  color: #3a4149;
  padding: 5px;
}

.space-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.space-card.mdc-card .space-card-row span.label {
  font-weight: normal;
}

.space-card-row span.label {
  padding: 0 10px;
  white-space: nowrap;
}

.space-card button {
  margin: 5px 0 0 0;
  width: 100%;
}

.mainUILabel {
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  padding: 0px 10px; 
  width: fit-content;
  color: white;
  font-weight: bold;
  font-size: x-large;
}

#webpageSidebar{
  width: 100%;
  height: 100%;
  z-index: 3;
  float: left;
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
}

.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}
#hamburgerButton.hide {
  display: none;
}

.sidebar {
  width: 100%;
  height: 100%;
  min-width: 330px; /* TODO revise min width with pseudo sidebar and md tabs */
  z-index: 3;
  float: left;
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
  overflow: auto;
  
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #3a4149;
  background: rgba(231, 231, 231, 0.85);
  
  transition: all 0.35s;
  -o-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;

  transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
}
.sidebar.open {
  visibility:visible;
  left: 0;

	transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.sidebarPseudo {
  width: 0%;
  min-width: 0px;
  height: 100%;
  
  display: block;
  position: relative;
  float: left;

	transition: all 0.35s;
  -o-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}
.sidebarPseudo.open {
  width: 100%; /* Keep same as .sidebar width property */
  min-width: 330px; /* Keep same as .sidebar min-width property */
  background: rgba(255,255,255,0.1);

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}


.btn_container {
  width: 5%;
  /* height: 10%; */
  aspect-ratio: 1 / 1;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}

.pulse-button-dark {
  position: relative;
  width: 33%;
  height: 33%;
  border: none;
  box-shadow: 0 0 0 0 rgba(45, 45, 45, 0.7);
  border-radius: 50%;
/*  background-color: #e84c3d;*/
  background-color:#4794d4;
  border: 2px solid #333333;
  background-size:cover;
  background-repeat: no-repeat;
  cursor: pointer;
  left: -20%;
  top: -20%;
}
.pulse-button-dark:hover {
  -webkit-animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
  -moz-animation: pulse 1s  cubic-bezier(0.10, 0, 0, 1);
  -ms-animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
  animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
}
.navigation-icon {
  position: relative;
  width: 90%;
  height: 90%; 
  left: -30%;
  top: -30%;
  border: none;
  box-shadow: 0 0 0 0 rgba(252, 0, 0, 0.7);
/*  background-color: #e84c3d;*/
  color:#4794d4;
  cursor: default;
}



div#helpScreen .close,
.sidebar .close {
  float: right;
  margin-left: auto;
  background: none;
}

.sidebar .close {
  align-self: end;
}

div#helpScreen .close:hover,
.sidebar .close:hover {
  color: black;
}

.tabGroup {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.tabGroup.hide {
  display: none;
}

.sidebar h2 {
  margin: 10px;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarContent div.modal-content {
  display: block;
  flex-shrink: 2;
  background-color: transparent;
  text-align: justify;
  font-weight: 400;
  font-size: calc(12px + 0.5vw);
  letter-spacing: 0px;
  padding: 10px;
  box-sizing: border-box;
}
div.modal-content h3 {
  text-align: initial;
}
div.modal-content.modal-content-IFrame {
  padding: 0;
  line-height: 0;
}
div.modal-content iframe.widgetInfoIFrame {
  width: 100%;
  height: 100%;
  border: 0;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track {
  background: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(105, 105, 105, 0.7);
  border: transparent;
}

h2#sidebarHeader {
  margin: 0;
  padding: 0px 10px;
  align-self: center;
}

button.mdc-tab {
  flex: 1;
}

.sidebarContent ul {
  padding: 0;
}
.sidebarContent ul li {
  padding: 10px 30px;
  text-decoration: none;
  list-style-type: none;

  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.animateLoad {
  
  opacity: 0;

  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  
	transform: translate3d(-10%, 0, 0);
  -o-transform: translate3d(-10%, 0, 0);
  -moz-transform: translate3d(-10%, 0, 0);
  -webkit-transform: translate3d(-10%, 0, 0);
}
#guidanceCard.animateLoad {
  /* width: 0px;
  transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -webkit-transform: translate3d(-100%, 0, 0); */
  width: fit-content;
  margin-left: -300px;
  max-height: 0;
}
#guidanceCard.animateLoad.loaded {
  
  transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  margin-left: 0px;
  max-height: 140px;
}

#carouselContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
}

#carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

#timelineButton.active {
  background-color: var(--mdc-theme-primary);
}

#timeline-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: auto;
}
#timeline-controls {
  display: flex;
}
#timeline-wrapper {
  width: 100%;
  display: flex;
  margin: 0;

  background-image: linear-gradient(white, white);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: center center;

  --mask-feather: 32px;
  --mask-image-content: linear-gradient(
        to right,
        transparent,
        black var(--mask-feather),
        black calc(100% - var(--mask-feather)),
        transparent
    );
    mask-image: var(--mask-image-content);
    -webkit-mask-image: var(--mask-image-content);
}

.timeline {
  display: flex;
  flex-flow: nowrap;

	height: 20px;
  padding: 0;
  margin: 0;
  
	text-align: justify;
  color: white;
	list-style: none;
}

.swiper-slide {
  width: 33vw;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
}
.timeline li div.timeline-button {
  grid-row: 2;
	width: 20px;
	height: 20px;
  margin: 10px auto;
	background: #ffffff;
  outline: white solid 2px;
	border-radius: 50%;
  cursor: pointer;
}
.timeline li.active div.timeline-button {
  background-color: var(--mdc-theme-primary);
  cursor: unset;
}
.timeline li div.timeline-text {
  grid-row: 3;

  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.timeline-button-next,
.timeline-button-prev {
  background: none !important;
  padding: 0;

  transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
}
.timeline-button-next .material-icons,
.timeline-button-prev .material-icons {
  font-size: 48px;
  color: var(--mdc-theme-primary);
}
.swiper-button-disabled{
  opacity: 0;
  pointer-events: none;
}

.swiper-pagination {
  position: relative !important;
}
.swiper-pagination-bullet-active {
  color: var(--mdc-theme-primary);
}


.guidanceCarouselImageWrapper {
  display: inline-block;
  overflow: hidden;
  margin: 5px;

  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.guidanceCarouselImageWrapper.Active {
  border: white 3px solid;
}

.guidanceCarouselImage {
  height: 10vw;
  width: 10vw;
  min-width: 64px;
  min-height: 64px;
  background-size: 200%;
  background-position: center;

  cursor: pointer;

  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.guidanceCarouselImage:hover {
  filter: brightness(1.25);
  -ms-filter: brightness(1.25);
  -webkit-filter: brightness(1.25);

  /* transform:scale(1.5);
  -o-transform:scale(1.5);
  -ms-transform:scale(1.5);
  -moz-transform:scale(1.5);
  -webkit-transform:scale(1.5); */
  background-size: 300%;
}

.guidanceCarouselImageWrapper.Active .guidanceCarouselImage {
  pointer-events: none;

  filter: brightness(0.5);
  -ms-filter: brightness(0.5);
  -webkit-filter: brightness(0.5);
}

.sidebarContent div.modal-content.animateLoad {
  transition-delay: 100ms;
  -o-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
}

.animateLoad.loaded {
  opacity: 1;

  transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.sidebarContent ul li:hover {
  background-color: #e7ebee;
}

.sidebarContent .modal-content {
  width: 100%;
}

/* tablets, ipads + */
@media (min-width:641px) {
  .sidebar{ 
    width: 50%;
  }
  .sidebarPseudo.open {
    width: 50%;
  }
}


/* laptops + */
@media (min-width:961px) {
  #timeline-container {
    width: 75vw;
  }
  .sidebar{ 
    width: 40%;
  }
  .sidebarPseudo.open {
    width: 40%;
  }

  .guidanceCarouselImage {
    width: 10vw;
    height: 10vh;
  }
}

.loader-background {
  z-index: 1111;
  background-color: black;
  color: black;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 150px;
  height: 190px;
  position: relative;
}
.loader::before, .loader::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 30px;
  left: 15px;
  z-index: 1;

  transform: scale(0);
  -o-transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);

  transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}
.loader .img {
  width: 80%;
  height: 100%;
  border-radius: 30%;
}
.loader .txt {
  width: 120%;
  text-align: center;
  position: absolute;
  bottom: -30px;
  left: -17%;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  color: white;
}
.loader .code {
  position: absolute;
  display:flex;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  flex-direction: column;
  align-items: center;

  z-index: 99;
  bottom: -3px;
  left: -17%;

  animation-name: spaceboots;
  -o-animation-name: spaceboots;
  -moz-animation-name: spaceboots;
  -webkit-animation-name: spaceboots;

  animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  
  transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;

  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}
.loader .box {
  width: 100%;
  height: 140px;
  display: block;
  color: white;
  position: absolute;
  top: -70px;
  left: -18px;
  z-index: 2;
  overflow: hidden;
}
.loader .box::before, .loader .box::after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 25px;
  opacity: 0;

  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader .box:nth-child(1)::before {
  font-size: 20px;
  content: "\f03d";
  animation: a 1.1s linear infinite;
  -o-animation: a 1.1s linear infinite;
  -moz-animation: a 1.1s linear infinite;
  -webkit-animation: a 1.1s linear infinite;
}
.loader .box:nth-child(1)::after {
  content: "\f03e";
  animation: b 1.3s linear infinite;
  -o-animation: b 1.3s linear infinite;
  -moz-animation: b 1.3s linear infinite;
  -webkit-animation: b 1.3s linear infinite;
}
.loader .box:nth-child(2)::before {
  content: "\f028";
  font-size: 25px;
  animation: c 0.9s linear infinite;
  -o-animation: c 0.9s linear infinite;
  -moz-animation: c 0.9s linear infinite;
  -webkit-animation: c 0.9s linear infinite;
}
.loader .box:nth-child(2)::after {
  content: "\f0c1 ";
  animation: d 0.7s linear infinite;
  -o-animation: d 0.7s linear infinite;
  -moz-animation: d 0.7s linear infinite;
  -webkit-animation: d 0.7s linear infinite;
}
.loader.amit::before, .loader.amit::after {
  transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
}

@keyframes a {
  0% {
    opacity: 0;
    transform: translate(30px, 0) rotate(30deg);
    -o-transform: translate(30px, 0) rotate(30deg);
    -moz-transform: translate(30px, 0) rotate(30deg);
    -webkit-transform: translate(30px, 0) rotate(30deg);
  }
  100% {
    opacity: 1;
    transform: translate(30px, 150px) rotate(-50deg);
    -o-transform: translate(30px, 150px) rotate(-50deg);
    -moz-transform: translate(30px, 150px) rotate(-50deg);
    -webkit-transform: translate(30px, 150px) rotate(-50deg);
  }
}
@keyframes b {
  0% {
    opacity: 0;
    transform: translate(50px, 0) rotate(-40deg);
    -o-transform: translate(50px, 0) rotate(-40deg);
    -moz-transform: translate(50px, 0) rotate(-40deg);
    -webkit-transform: translate(50px, 0) rotate(-40deg);
  }
  100% {
    opacity: 1;
    transform: translate(40px, 150px) rotate(80deg);
    -o-transform: translate(40px, 150px) rotate(80deg);
    -moz-transform: translate(40px, 150px) rotate(80deg);
    -webkit-transform: translate(40px, 150px) rotate(80deg);
  }
}
@keyframes c {
  0% {
    opacity: 0;
    transform: translate(70px, 0) rotate(10deg);
    -o-transform: translate(70px, 0) rotate(10deg);
    -moz-transform: translate(70px, 0) rotate(10deg);
    -webkit-transform: translate(70px, 0) rotate(10deg);
  }
  100% {
    opacity: 1;
    transform: translate(60px, 150px) rotate(70deg);
    -o-transform: translate(60px, 150px) rotate(70deg);
    -moz-transform: translate(60px, 150px) rotate(70deg);
    -webkit-transform: translate(60px, 150px) rotate(70deg);
  }
}
@keyframes d {
  0% {
    opacity: 0;
    transform: translate(30px, 0) rotate(-50deg);
    -o-transform: translate(30px, 0) rotate(-50deg);
    -moz-transform: translate(30px, 0) rotate(-50deg);
    -webkit-transform: translate(30px, 0) rotate(-50deg);
  }
  100% {
    opacity: 1;
    transform: translate(45px, 150px) rotate(30deg);
    -o-transform: translate(45px, 150px) rotate(30deg);
    -moz-transform: translate(45px, 150px) rotate(30deg);
    -webkit-transform: translate(45px, 150px) rotate(30deg);
  }
}
@keyframes spaceboots {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
    -o-transform: translate(2px, 1px) rotate(0deg);
    -moz-transform: translate(2px, 1px) rotate(0deg);
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
    -o-transform: translate(-1px, -2px) rotate(-1deg);
    -moz-transform: translate(-1px, -2px) rotate(-1deg);
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
    -o-transform: translate(-3px, 0px) rotate(1deg);
    -moz-transform: translate(-3px, 0px) rotate(1deg);
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
    -o-transform: translate(0px, 2px) rotate(0deg);
    -moz-transform: translate(0px, 2px) rotate(0deg);
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
    -o-transform: translate(1px, -1px) rotate(1deg);
    -moz-transform: translate(1px, -1px) rotate(1deg);
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
    -o-transform: translate(-1px, 2px) rotate(-1deg);
    -moz-transform: translate(-1px, 2px) rotate(-1deg);
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
    -o-transform: translate(-3px, 1px) rotate(0deg);
    -moz-transform: translate(-3px, 1px) rotate(0deg);
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
    -o-transform: translate(2px, 1px) rotate(-1deg);
    -moz-transform: translate(2px, 1px) rotate(-1deg);
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
    -o-transform: translate(-1px, -1px) rotate(1deg);
    -moz-transform: translate(-1px, -1px) rotate(1deg);
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
    -o-transform: translate(2px, 2px) rotate(0deg);
    -moz-transform: translate(2px, 2px) rotate(0deg);
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
    -o-transform: translate(1px, -2px) rotate(-1deg);
    -moz-transform: translate(1px, -2px) rotate(-1deg);
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}